table {
  border-collapse: separate!important;
  border-spacing: 0!important;
}
td {
  position: relative;
  vertical-align: top;
  padding: 0 0 50px 0;
  text-align: center;
}
.parent {
  background: #409EFF !important;
  font-weight: 600;
  font-size: 16px;
  color: #fFF;
}
.extend_handle {
  position: absolute;
  left: 50%;
  bottom: 18px;
  width: 27px;
  height: 27px;
  padding: 10px;
  transform: translate3d(-14px, 0, 0);
  cursor: pointer;
}
.extend_handle:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid;
  border-color: #ccc #ccc transparent transparent;
  transform: rotateZ(135deg);
  transform-origin: 50% 50% 0;
  transition: transform ease 300ms;
}
.extend_handle:hover:before {
  border-color: #333 #333 transparent transparent;
}
.extend .extend_handle:before {
  transform: rotateZ(-45deg);
}
.extend::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 15px;
  height: 15px;
  border-left: 2px solid #ccc;
  transform: translate3d(-1px, 0, 0);
}
.childLevel::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 100%;
  height: 15px;
  border-left: 2px solid #ccc;
  transform: translate3d(-1px, 0, 0);
}
.childLevel::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  border-top: 2px solid #ccc;
}
.childLevel:first-child:before,
.childLevel:last-child:before {
  display: none;
}
.childLevel:first-child:after {
  left: 50%;
  height: 15px;
  border: 2px solid;
  border-color: #ccc transparent transparent #ccc;
  border-radius: 6px 0 0 0;
  transform: translate3d(1px, 0, 0);
}
.childLevel:last-child:after {
  right: 50%;
  height: 15px;
  border: 2px solid;
  border-color: #ccc #ccc transparent transparent;
  border-radius: 0 6px 0 0;
  transform: translate3d(-1px, 0, 0);
}
.childLevel:first-child.childLevel:last-child::after {
  left: auto;
  border-radius: 0;
  border-color: transparent #ccc transparent transparent;
  transform: translate3d(1px, 0, 0);
}
.node {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  padding: 0 5px;
}
.node .person {
  padding-top: 15px;
  position: relative;
  display: inline-block;
  z-index: 2;
  width: 120px;
  overflow: hidden;
}
.node .person .avat {
  padding: 5px;
  padding-top: 10px;
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
  word-break: break-all;
  background: #bbb8b8;
  box-sizing: border-box;
  border-radius: 4px;
}
.node .person .avat .opreate_icon {
  display: none;
}
.node .person .avat:hover2 .opreate_icon {
  display: block;
  position: absolute;
  top: -3px;
  right: -3px;
  padding: 5px;
}
.node .person .avat.company {
  background: #20242bf2;
}
.node .person .avat.other {
  background: #ccc;
}
.node .person .avat img {
  cursor: pointer;
}
.node .person .name {
  height: 2em;
  line-height: 2em;
  overflow: hidden;
  width: 100%;
}
.node.hasMate::after {
  content: "";
  position: absolute;
  left: 2em;
  right: 2em;
  top: 15px;
  border-top: 2px solid #ccc;
  z-index: 1;
}
.node.hasMate .person:last-child {
  margin-left: 1em;
}
.el-dialog__header {
  padding: 0;
  padding-top: 30px;
  margin: 0 30px;
  border-bottom: 1px solid #F1F1F1;
  text-align: left;
}
.el-dialog__header .el-dialog__title {
  font-size: 14px;
  font-weight: bold;
  color: #464C5B;
  line-height: 20px;
}
.tips {
  padding: 0 20px;
}
.tips .el-select {
  width: 100%;
}
.tips .blue {
  color: #00B5EF;
}
.tips .check {
  margin-left: 100px;
}
.tips .inquiry {
  font-weight: bold;
}
.tips .el-form-item__label {
  display: block;
  float: none;
  text-align: left;
}
.tips .el-form-item__content {
  margin-left: 0;
}
.el-dialog__body {
  padding: 30px 25px;
}
.el-dialog__body p {
  margin-bottom: 15px;
}
.el-dialog__headerbtn {
  top: 30px;
  right: 30px;
}
.landscape {
  transform: translate(-100%, 0) rotate(-90deg);
  transform-origin: 100% 0;
}
.landscape .node {
  text-align: left;
  height: 8em;
  width: 8em;
}
.landscape .person {
  position: relative;
  transform: rotate(90deg);
  top: 35px;
  left: 12px;
  width: 110px;
}
.el-popover .el-button {
  padding: 8px !important;
  margin-left: 5px !important;
  float: left;
}
